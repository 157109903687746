<template>
  <div class="register left-float-top pt-20">
    <!-- 二级菜单 -->
    <!-- <div class="secondMenu">
      <div class="secondMenu-item" v-for="item in menus" :key="item.code" :class="active == item.url ? 'active' : ''"><router-link :to="item.url">{{item.label}}</router-link></div>
    </div> -->
    <div class="flex-1"><RouterView /></div>
  </div>
</template>
<script>
export default{
  data() {
    return{
      active:'/certificate/authority',
      menus: [
        {code: 'c1', url: '/certificate/authority', label: '认证机构介绍'},
        {code: 'c2', url: '/certification/process', label: '认证流程介绍'},
        {code: 'c3', url: '/certification/purpose', label: '认证的目的及意义'},
        {code: 'c4', url: '/certification/standards', label: '胸痛中心认证标准'},
        {code: 'c5', url: '/certification/standards/basic', label: '基层胸痛中心认证标准'},
        {code: 'c6', url: '/supervision/system', label: '监督管理制度'}
      ]
    }

  },
  watch: {
    $route: {
      deep: true,
      handler(n, o) {
        console.log(n.path)
        this.active = n.path
      }
    }
  },
  mounted() {
    
  },
}
</script>
<style>
</style>
